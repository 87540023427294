@import "../../_globalColor";

.talk-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px;
}

.talk-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.subTitle {
  color: $subTitle;
}


@media (max-width: 768px) {
  .talk-header-title {
    font-size: 36px;
    text-align: center;
  }
  .talk-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .talk-cards-div-main {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }
}
